.container-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header-container{
  width: 60%;
}

.form-container {
  width: 70%;
}

.logo-div {
  margin: 4% 4%;
}

.logo-image {
  width: 30px;
  height: 30px;
  margin: 3px;
}

.outlet-div {
  width: 90%;
  margin: 1% 5%;
}

.form-label {
  width: 60%;
}

.gender {
  font-size: larger;
  padding: 5px 0px;
  margin: 5px !important;
}

.age-div {
  padding: 5px 5px;
  width: 50%;
}

.vertical-div {
  width: 50%;
}

.section-heading {
  font-weight: bold !important;
  margin: 0;
  padding: 0;
}

.submit-button {
  background-color: #00bf6f !important;
  color: #fff !important;
  font-family: National2 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  float: none !important;
  font-size: 18px !important;
  border: 1px solid transparent !important;
  margin-left: 0 !important;
  opacity: 1 !important;
  padding: 15px 25px !important;
}

.submit-button:hover {
  box-shadow: 0 0 4px #00bf6f !important;
  outline-offset: 5px !important;
  outline: 1px solid #333e48 !important;
}

.submit-button:disabled{
  background-color: grey !important;
  color: black !important;
}

.work-label{
  font-size: large;
  font-weight: 600;
}

.button-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errormsg{
  font-size: large;
  color: red;
}

@media (max-width: 992px) {

  .outlet-div{
    width: 94%;
  }

  .age-div{
    width: 90%;
  }

  .vertical-div{
    width: 90%;
  }

  .form-container{
    width: 90%;
  }

  .header-container{
    width: 98%;
  }
}